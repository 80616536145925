import React, { type ReactElement, useContext } from 'react'
import { type LessonData } from '../../constants/types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { LESSON_PATH } from '../../constants/rolePaths'
import { useRolePath } from '../../hooks/useRolePath'
import { CurrentUserContext } from '../../contexts/users/CurrentUserContext'
import DefaultAvatar from '../../assets/default_avatar.png'
import { TEACHER_ROLE, STUDENT_ROLE } from '../../constants/roles'

const LessonListItem = ({ lesson }: { lesson: LessonData }): ReactElement => {
  const { t } = useTranslation('translations')
  const classUnitNames = lesson.class_units && lesson.class_units.length > 0
    ? lesson.class_units.map(unit => unit.name).join(', ')
    : 'brak klas'

  const { currentUser } = useContext(CurrentUserContext)
  const rolePath = useRolePath(LESSON_PATH, currentUser)
  const lessonViewPath = rolePath.replace(':id', lesson.id.toString())

  const linkConfigs = [
    {
      path: '',
      label: t('lessons.links.topics')
    },
    {
      path: '',
      label: currentUser?.role === STUDENT_ROLE
        ? t('lessons.links.my_attendances')
        : t('lessons.links.attendances')
    },
    {
      path: '',
      label: currentUser?.role === STUDENT_ROLE
        ? t('lessons.links.my_grades')
        : t('lessons.links.grades')
    },
    {
      path: '',
      label: t('lessons.links.announcements')
    },
    {
      path: '',
      label: t('lessons.links.files')
    }
  ]

  const LinkComponent = (): ReactElement => (
    <>
      {linkConfigs.map(({ path, label }) => (
        <Link
          key={label}
          to={path}
          className="px-4 py-1 lg:mx-1 w-max border border-1 border-blue-600 rounded-md text-blue-600 hover:text-white hover:bg-blue-600"
        >
          {label}
        </Link>
      ))}
    </>
  )

  const MobileLinks = (): ReactElement => (
    <>
      <div className="grid grid-flow-col auto-cols-max gap-2">
        {linkConfigs.slice(0, 2).map(({ path, label }) => (
          <Link
            to={path}
            key={path}
            className="px-4 py-1 lg:mx-1 w-max border border-1 border-blue-600 rounded-md text-blue-600 hover:text-white hover:bg-blue-600"
          >
            {label}
          </Link>
        ))}
      </div>
      <div className="grid grid-flow-col auto-cols-max gap-2">
        {linkConfigs.slice(2).map(({ path, label }) => (
          <Link
            key={path}
            to={path}
            className="px-4 py-1 lg:mx-1 w-max border border-1 border-blue-600 rounded-md text-blue-600 hover:text-white hover:bg-blue-600"
          >
            {label}
          </Link>
        ))}
      </div>
    </>
  )

  return (
    <div className="card grid grid-cols-1 lg:grid-cols-12 gap-2 py-3 px-5 text-sm flex items-center">
      <div className="col-span-3 hidden lg:block">
        <Link
          to={lessonViewPath}
          className="text-blue-600 hover:text-blue-900"
        >
          {lesson.subject.name}
        </Link>
      </div>
      {currentUser?.role === TEACHER_ROLE && (
        <div className="col-span-3 hidden lg:block">
          {classUnitNames}
        </div>
      )}
      {currentUser?.role === STUDENT_ROLE && (
        <div className="col-span-3 hidden lg:block">
          <div className="flex gap-2 items-center font-['DM Sans'] truncate">
            <img
              src={lesson?.teacher?.user?.avatar_url ?? DefaultAvatar}
              className="object-cover cursor-pointer h-6 w-6 rounded-full"
              alt="Avatar"
            />
            {lesson?.teacher?.user?.name}
          </div>
        </div>
      )}
      <div className="col col-span-1 block lg:hidden">
        <Link
          to={lessonViewPath}
          className="text-blue-600 hover:text-blue-900 text-xl"
        >
          {lesson.subject.name}
        </Link>
        <div className="pt-2">
          {t('lessons.heading.class_units')}: {classUnitNames}
        </div>
        <div className="flex gap-3 items-center font-['DM Sans']">
          {t('lessons.heading.teacher')}:
          <img
            src={lesson?.teacher?.user?.avatar_url ?? DefaultAvatar}
            className="object-cover cursor-pointer h-6 w-6 rounded-full"
            alt="Avatar"
          />
          {lesson?.teacher?.user?.name}
        </div>
      </div>
      <div className="lg:hidden block grid grid-cols-1 gap-2 pt-3">
        <MobileLinks />
      </div>
      <div className="col-span-6 inline-flex justify-end lg:inline-flex hidden">
        <LinkComponent />
      </div>
    </div>
  )
}

export default LessonListItem
