import React, { useContext } from 'react'
import { type NewsData } from '../../constants/types'
import { NEWS_DETAILS_VIEW } from '../../constants/routes'
import { ADMIN_EDIT_NEWS_PATH } from '../../constants/adminRoutes'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import EllipsisVerticalIcon from '../../assets/icons/ellipsis_vertical.svg'
import Dropdown, { DropdownItem, DropdownHead, DropdownMenu } from '../common/Dropdown'
import { useParseDate } from '../../hooks/useParseDate'
import { useApiPost } from '../../hooks/api/useApiPost'
import { API_NEWS_READINGS_PATH } from '../../constants/apiRoutes'
import { NotReadRequiredNewsContext } from '../../contexts/news/NotReadRequiredNewsContext'
import { CurrentUserContext } from '../../contexts/users/CurrentUserContext'

const NewsListItem = ({ news }: { news: NewsData }): React.ReactElement => {
  const { t } = useTranslation('translations')
  const { toLocalDate } = useParseDate()
  const navigate = useNavigate()
  const { currentUser } = useContext(CurrentUserContext)
  const { notReadRequiredNewsCount, setNotReadRequiredNewsCount } = useContext(NotReadRequiredNewsContext)

  const { sendData: sendMarkAsRead } = useApiPost({ url: API_NEWS_READINGS_PATH.replace(':news_id', news.id.toString()) })
  const newsBackground = news.read ? 'bg-gray-100 hover:bg-gray-200' : 'bg-white hover:bg-gray-100'
  const notReadDotColor = news.required_to_read ? 'bg-red-500' : 'bg-blue-600'
  const newsItemXPadding = news.pinned ? '' : 'px-5'

  const sendMarkAsUnreadRequest = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>): Promise<void> => {
    e.preventDefault()
    e.stopPropagation()

    await sendMarkAsRead({ method: 'delete' })
    if (news.required_to_read) {
      setNotReadRequiredNewsCount(notReadRequiredNewsCount + 1)
    }

    window.location.reload()
  }

  const sendMarkAsReadRequest = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>, redirect: boolean): Promise<void> => {
    e.preventDefault()
    e.stopPropagation()
    await sendMarkAsRead({ additionalParams: { news_id: news.id } })

    if (news.required_to_read) {
      setNotReadRequiredNewsCount(notReadRequiredNewsCount - 1)
    }

    if (redirect) {
      navigate(NEWS_DETAILS_VIEW.replace(':id', news.id.toString()))
    } else {
      window.location.reload()
    }
  }

  const navigateToAdminNewsEditView = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>): Promise<void> => {
    e.preventDefault()
    e.stopPropagation()

    navigate(ADMIN_EDIT_NEWS_PATH.replace(':id', news.id.toString()))
  }

  return (
    <div className={`${newsBackground} border border-gray-200 shadow-sm rounded-xl relative`}>
      {!news.read && (
        <div className={`absolute left-[7px] top-[14px] transform -translate-y-1/2 h-3 w-3 ${notReadDotColor} rounded-full`} />
      )}

      <div
        onClick={async (e) => { await sendMarkAsReadRequest(e, true) }}
        className="p-5 grid sm:grid-cols-12 gap-4 cursor-pointer"
      >
        <div className="sm:col-span-2 sm:order-2 flex justify-end">
          <Dropdown>
            <DropdownHead>
              <img src={EllipsisVerticalIcon} alt="Elipsis" onClick={(e) => { e.stopPropagation() }}/>
            </DropdownHead>
            {/* FIXME: Stop redirection on click */}
            <DropdownMenu>
              <>
                <DropdownItem
                  text={news.read ? t('news.mark_as_not_read') : t('news.mark_as_read')}
                  action={news.read ? sendMarkAsUnreadRequest : async (e) => { await sendMarkAsReadRequest(e, false) }}
                />
                <>
                  {currentUser?.admin && (
                    <DropdownItem
                      text={t('admin.news.edit_button')}
                      action={navigateToAdminNewsEditView}
                    />
                  )}
                </>
              </>
            </DropdownMenu>
          </Dropdown>
        </div>

        <div className="sm:col-span-10 sm:order-1">
          <div className="h-full flex flex-col justify-between space-y-3">
            <div className="flex">
              {news.pinned && (
                <svg
                  onClick={(e) => { e.stopPropagation() }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-8 w-8 ml-4 mt-3 mr-8 flex-shrink-0"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                  />
                </svg>
              )}
              <div className={`${newsItemXPadding} w-full`}>
                <h3 className="text-2xl sm:text-lg font-semibold text-gray-900">
                  {news.title}
                </h3>
                <div className="truncate text-sm text-gray-700">
                  {news.content_as_text}
                </div>
                <p className="mt-1 sm:mt-3 text-xs text-gray-500">
                  {t('news.created_at')} {toLocalDate(news.created_at)},
                  {t('news.updated_at')} {toLocalDate(news.updated_at)},
                  {t('news.author')} {news.created_by.name}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsListItem
