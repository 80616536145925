import React, { type ReactElement, useEffect, useState } from 'react'
import { MobileMenuContext } from './MobileMenuContext'

const MobileMenuContextProvider = ({ children }: { children: ReactElement | null }): ReactElement => {
  const [renderMobileMenu, setRenderMobileMenu] = useState(true)
  const [renderMobileNavbar, setRenderMobileNavbar] = useState(window.matchMedia('(min-width: 1024px)').matches)

  useEffect(() => {
    const handleResize = (): void => {
      setRenderMobileNavbar(window.matchMedia('(min-width: 960px)').matches)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <MobileMenuContext.Provider value={
      { renderMobileMenu, setRenderMobileMenu, renderMobileNavbar, setRenderMobileNavbar }
    }>
      {children}
    </MobileMenuContext.Provider>
  )
}

export default MobileMenuContextProvider
