import React, { type ReactElement, useEffect } from 'react'
import { type SelectOptions } from '../../constants/types'
import { map, isEmpty } from 'underscore'
import { renderToString } from 'react-dom/server'
import HSSelect from '@preline/select'

const MultiSelect = ({ options, search = false, placeholder, onChange, id }: { options: SelectOptions[], search?: boolean, placeholder?: string, onChange: (e: string) => any, id: string }): ReactElement => {
  const selectOptions = {
    placeholder: placeholder ?? 'Wybierz opcję...',
    hasSearch: search,
    searchClasses: 'block w-full text-sm border-gray-200 rounded-lg focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] py-2 px-3 border rounded-lg',
    searchWrapperClasses: 'bg-white p-2 -mx-1 sticky top-0',
    toggleTag: renderToString(<ToggleTag />),
    optionTemplate: renderToString(<OptionTemplate />),
    extraMarkup: renderToString(<ExtraMarkup />),
    toggleClasses: 'hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 ps-4 pe-9 flex gap-x-2 text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:outline-none focus:ring-2 focus:ring-blue-500',
    dropdownClasses: 'mt-2 z-50 w-full max-h-72 p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300',
    optionClasses: 'py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50'
  }

  useEffect(() => {
    const element = document.querySelector(`#${id}`) as HTMLElement
    if (element) HSSelect.autoInit()
  }, [options])

  const handleChange = (): void => {
    const element = document.querySelector(`#${id}`) as HTMLElement
    const selectInstance = HSSelect.getInstance(element) as HSSelect
    const selectInstanceValue = selectInstance.value as string[]

    onChange(selectInstanceValue.join(','))
  }

  // We need to check if options are empty because preline select can't load before options
  return (
    <div className="w-[175px]">
      {
        !isEmpty(options) && (
          <select
            multiple={true}
            data-hs-select={JSON.stringify(selectOptions)}
            className="hidden w-48"
            id={id}
            onChange={handleChange}
          >
            {map(options, (option: SelectOptions) => {
              return <option value={option.value} disabled={option.disabled}>{option.text}</option>
            })}
          </select>
        )
      }
    </div>
  )
}

const OptionTemplate = (): ReactElement => {
  return (
    <div className="flex justify-between items-center w-full">
      <span data-title />
      <span className="hidden hs-selected:block">
        <svg className="shrink-0 size-3.5 text-blue-600"
          xmlns="http:.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
          fill="none" stroke="currentColor" strokeWidth="2"
          strokeLinecap="round" strokeLinejoin="round">
          <polyline points="20 6 9 17 4 12"/>
        </svg>
      </span>
    </div>
  )
}

const ExtraMarkup = (): ReactElement => {
  return (
    <div className="absolute top-1/2 end-3 -translate-y-1/2">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
        <path fillRule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
      </svg>
    </div>
  )
}

const ToggleTag = (): ReactElement => {
  return (
    <button type="button" aria-expanded="false" />
  )
}

export default MultiSelect
