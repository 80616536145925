import React, { type ReactElement } from 'react'

const ActiveTabFilter = ({ setFilters, text }: { setFilters: (prevFilters: any) => void, text: string }): ReactElement => {
  return (
    <div
      className="px-3 py-1.5 bg-[#d6efff] rounded-lg shadow justify-start items-center gap-2 flex cursor-pointer"
      onClick={setFilters}
    >
      <div className="justify-center items-center gap-2 flex">
        <div className="text-[#0e459a] text-sm font-medium font-['DM Sans'] leading-normal">{text}</div>
      </div>
    </div>
  )
}

export default ActiveTabFilter
