import React, { type ReactElement, useState } from 'react'
import { type CurrentUserData } from '../../constants/types'
import { CurrentUserContext } from './CurrentUserContext'
import { LOGIN_PATH } from '../../constants/routes'

const CurrentUserContextProvider = ({ children }: { children: ReactElement }): ReactElement => {
  const [currentUser, setCurrentUser] = useState(null as CurrentUserData | null)

  const logout = (): void => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    window.location.href = LOGIN_PATH
  }

  return (
    <CurrentUserContext.Provider value={{ currentUser, setCurrentUser, logout }}>
      {children}
    </CurrentUserContext.Provider>
  )
}

export default CurrentUserContextProvider
