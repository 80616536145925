import { jwtDecode } from 'jwt-decode'
import { type CurrentUserData } from '../../constants/types'

class GetCurrentUserFromTokenService {
  call (): CurrentUserData | null {
    try {
      const token = localStorage.getItem('access_token')
      const currentUserData = (token ? jwtDecode(token) : null) as CurrentUserData

      if (currentUserData?.avatar_url) {
        currentUserData.avatar_url = process.env.REACT_APP_BACKEND_URL + currentUserData.avatar_url
      }

      return currentUserData
    } catch (error) {
      console.error(error)
      return null
    }
  }
}

export default new GetCurrentUserFromTokenService()
