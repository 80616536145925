import React, { type ReactElement } from 'react'
import { type SearchResultData } from '../../constants/types'
import DefaultAvatar from '../../assets/default_avatar.png'
import { useTranslation } from 'react-i18next'

const AdvancedSearchingResultItem = ({ item }: { item: SearchResultData }): ReactElement => {
  const { t } = useTranslation('translations')

  return (
    <tr className="border-b border-b border-[#ebebeb] h-16">
      <td className="px-5 py-3 flex items-center gap-2.5 h-full">
        <Icon item={item} />
        <span className="text-[#2f2f2f] text-sm font-normal leading-normal">{item.name}</span>
      </td>
      <td className="px-5 py-3 text-[#2f2f2f] text-sm font-medium leading-normal h-full">
        {item.type && item.category === 'User' ? t(`searchbar.types.${item.type}`) : item.type}
      </td>
      <td className="px-5 py-3 text-[#2f2f2f] text-sm font-medium leading-normal h-full">
        {t(`searchbar.categories.${item.category}`)}
      </td>
      <td className="px-5 py-3 flex items-center gap-1 h-full">
        {item.status === 'archived' ? <ArchivedLabel /> : <ActiveLabel />}
      </td>
    </tr>
  )
}

const Icon = ({ item }: { item: SearchResultData }): ReactElement => {
  if (item.category === 'User') {
    return <img className="w-5 h-5 rounded-full" src={item.avatar_url || DefaultAvatar} alt={item.name} />
  } else if (item.category === 'ClassUnit') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
        <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
      </svg>
    )
  } else if (item.category === 'Lesson') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
      </svg>
    )
  } else {
    return <></>
  }
}

const ActiveLabel = (): ReactElement => {
  return (
    <div className="px-[6px] py-px bg-[#b8fadd] rounded-md">
      <div className="text-[#11563a] text-sm font-medium leading-normal tracking-tight">Aktywny</div>
    </div>
  )
}

const ArchivedLabel = (): ReactElement => {
  return (
    <div className="h-[26px] justify-start items-start inline-flex">
      <div className="px-[3px] py-px bg-[#ebebeb] rounded-md justify-start items-center gap-px flex">
        <div className="px-[2.50px] justify-start items-center gap-[7px] flex">
          <div className="text-[#3d3d3d] text-sm font-medium font-['DM Sans'] leading-normal tracking-tight">Archiwalny</div>
        </div>
      </div>
    </div>
  )
}

export default AdvancedSearchingResultItem
