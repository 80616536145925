import React, { type ReactElement, useEffect, useState } from 'react'
import AdvancedSearchingResultItem from '../components/search/AdvancedSearchingResultItem'
import EmptySearchTableBody from '../components/search/EmptySearchTableBody'
import TabFilter from '../components/filters/tabs/TabFilter'
import MultiSelect from '../components/common/MultiSelect'
import { useLocation } from 'react-router-dom'
import { useApiFetch } from '../hooks/api/useApiFetch'
import { API_SEARCHES_PATH, API_SEARCHES_TYPES_PATH } from '../constants/apiRoutes'
import { isEmpty, map, omit } from 'underscore'
import { type AdvancedSearchingFilters, type AdvancedSearchingCounters } from '../constants/types'
import Pagination from '../components/common/Pagination'

export const AdvancedSearchingView = (): ReactElement => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const queryString = queryParams.get('query')

  const [filters, setFilters] = useState({} as AdvancedSearchingFilters)
  const [counters, setCounters] = useState({} as AdvancedSearchingCounters)

  const { data: searchResult, fetchData } = useApiFetch({ url: API_SEARCHES_PATH, onRender: false, paginated: true })
  const { data: types } = useApiFetch({ url: API_SEARCHES_TYPES_PATH, paginated: true })

  useEffect(() => {
    fetchData(buildQueryParams()).then((result: any) => {
      if (isEmpty(counters)) setCounters(result.counters)
    })
  }, [filters, queryString])

  const fetchPaginatedData = async (page: Record<string, number>): Promise<void> => {
    await fetchData({ ...buildQueryParams(), ...page })
  }

  const buildQueryParams = (): AdvancedSearchingFilters => {
    const query = { query: queryString as string } as AdvancedSearchingFilters

    if (filters.category) query.category = filters.category
    if (filters.type) query.type = filters.type
    if (filters.status) query.status = filters.status
    if (filters.sort_by) query.sort_by = filters.sort_by

    return query
  }

  const setSortingParam = (paramName: string): void => {
    const sortParam = filters.sort_by === `${paramName}.desc` ? `${paramName}.asc` : `${paramName}.desc`

    setFilters((prevVFilters) => ({ ...prevVFilters, sort_by: sortParam }))
  }

  return (
    <div className="w-full flex justify-center mb-10">
      <div className="w-5/6">
        <div className="h-12 justify-start items-center gap-1 inline-flex mb-6">
          <div className="text-neutral-800 text-[32px] font-semibold font-['DM Sans'] leading-[48px] tracking-tight">
            {queryString}
          </div>
        </div>

        <div>
          <div className="h-11 justify-start items-center gap-3 inline-flex mb-6">
            <div className="h-11 p-1 bg-white rounded-xl justify-start items-start gap-2.5 flex w-full">
              <TabFilter
                setFilters={() => { setFilters((prevFilters: any) => omit(prevFilters, 'category')) }}
                text='Wszystkie'
                counter={counters?.sum}
                active={!filters.category}
              />
              <TabFilter
                setFilters={() => { setFilters((prevFilters: any) => ({ ...prevFilters, category: 'lessons' })) } }
                text='Przedmioty'
                counter={counters?.lessons}
                active={filters.category === 'lessons'}
              />
              <TabFilter
                setFilters={() => { setFilters((prevFilters: any) => ({ ...prevFilters, category: 'class_units' })) } }
                text='Klasy'
                counter={counters?.classunits}
                active={filters.category === 'class_units'}
              />
              <TabFilter
                setFilters={() => { setFilters((prevFilters: any) => ({ ...prevFilters, category: 'users' })) } }
                text='Użytkownicy'
                counter={counters?.users}
                active={filters.category === 'users'}
              />
            </div>
            <div className="justify-start items-start flex gap-4">
              <MultiSelect
                id="status-select"
                search={true}
                options={map(types, (type: string) => ({ text: type, value: type }))}
                placeholder='Rodzaj'
                onChange={(value) => { setFilters((prevFilters: any) => ({ ...prevFilters, type: value })) }}
              />
              <MultiSelect
                id="type-select"
                options={[{ value: 'active', text: 'Aktywne' }, { value: 'archived', text: 'Zarchiwizowane' }, { value: '', text: 'Wybierz...' }]}
                placeholder={'Status'}
                onChange={(value) => { setFilters((prevFilters: any) => ({ ...prevFilters, status: value })) }}
              />
            </div>
          </div>

          <div className="w-full bg-white rounded-md shadow border border-[#ebebeb] flex flex-col" style={{ minHeight: '650px' }}>
            <div className="flex-grow">
              <table className="w-full h-full table-fixed">
                <thead>
                  <tr className="bg-[#f2f2f3] border-t border-b border-[#d5d7d9]">
                    <th
                      className="px-5 py-3 text-left text-[#4e6173] text-xs font-medium leading-tight cursor-pointer"
                      onClick={() => { setSortingParam('name') }}
                    >
                      <div className="flex items-center gap-2">
                      NAZWA
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                        </svg>
                      </div>
                    </th>
                    <th
                      className="px-5 py-3 text-left text-[#4e6173] text-xs font-medium leading-tight cursor-pointer"
                      onClick={() => { setSortingParam('type') }}
                    >
                      <div className="flex items-center gap-2">
                      RODZAJ
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                        </svg>
                      </div>
                    </th>
                    <th className="px-5 py-3 text-left text-[#4e6173] text-xs font-medium leading-tight">
                      <div className="flex items-center gap-2">
                      TYP
                      </div>
                    </th>
                    <th className="px-5 py-3 text-left text-[#4e6173] text-xs font-medium leading-tight">
                      <div className="flex items-center gap-2">
                      STATUS
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {isEmpty(searchResult.data)
                    ? (
                      <EmptySearchTableBody />
                    )
                    : (
                      map(searchResult.data, (item) => (
                        <AdvancedSearchingResultItem item={item} key={item.id} />
                      ))
                    )}
                </tbody>

              </table>
            </div>

            <tfoot className="block w-full border-t border-[#d5d7d9]">
              <tr className="block w-full">
                <td colSpan={4} className="block w-full px-4 py-3">
                  <div className="flex justify-end w-full">
                    <Pagination paginationMeta={searchResult.meta} fetchData={fetchPaginatedData} />
                  </div>
                </td>
              </tr>
            </tfoot>
          </div>

        </div>
      </div>
    </div>
  )
}

export default AdvancedSearchingView
