import React, { type ReactElement } from 'react'
import CommentSection from '../components/comments/CommentSection'
import { API_NEWS_COMMENTS_PATH, API_NEWS_DETAILS_PATH } from '../constants/apiRoutes'
import { useParams } from 'react-router-dom'
import { useApiFetch } from '../hooks/api/useApiFetch'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'

const NewsDetailsView = (): ReactElement => {
  const queryParams = useParams()
  const { data: news } = useApiFetch({ url: API_NEWS_DETAILS_PATH.replace(':news_id', queryParams.id as string) })

  return (
    <div className="flex w-full justify-center">
      <div className="w-1/2">
        <div className="flex justify-center w-full">
          <FroalaEditorView model={news?.content} />
        </div>
        <CommentSection commentsApiUrl={API_NEWS_COMMENTS_PATH.replace(':news_id', queryParams.id as string)}/>
      </div>
    </div>
  )
}

export default NewsDetailsView
