import React, { type ReactElement } from 'react'
import CommentForm from './CommentForm'
import CommentItem from './CommentItem'
import { type CommentData } from '../../constants/types'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { map } from 'underscore'
import { API_NEWS_COMMENTS_PATH } from '../../constants/apiRoutes'
import { useParams } from 'react-router-dom'

const CommentSection = ({ commentsApiUrl }: { commentsApiUrl: string }): ReactElement => {
  const { data: comments, setData: setComments } = useApiFetch({ url: commentsApiUrl, paginated: true })
  const queryParams = useParams()
  const createCommentApiUrl = API_NEWS_COMMENTS_PATH.replace(':news_id', queryParams?.id as string)

  return (
    <section className="bg-white py-8 lg:py-16 antialiased">
      <div className="max-w-2xl mx-auto px-4">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-lg lg:text-2xl font-bold text-gray-900">
            {`Komentarze (${comments?.meta?.count})`}
          </h2>
        </div>
        {/* FIXME: Add comment and replies pagination */}
        <CommentForm setComments={setComments} apiUrl={createCommentApiUrl} />
        {
          map(comments?.data, (comment: CommentData) => {
            return <CommentItem key={comment.id} comment={comment} />
          })
        }
      </div>
    </section>
  )
}

export default CommentSection
