import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { useParams } from 'react-router-dom'
import { API_STUDENT_EDIT_HOMEWORK_SOLUTIONS_PATH } from '../../constants/apiRoutes'
import { map } from 'underscore'

const StudentHomeworkSolutionView = (): ReactElement => {
  const queryParams = useParams()
  const apiUrl = API_STUDENT_EDIT_HOMEWORK_SOLUTIONS_PATH
    .replace(':homeworkId', queryParams.homeworkId as string)
    .replace(':id', queryParams.id as string)
  const { data: homeworkSolution } = useApiFetch({ url: apiUrl })
  const { t } = useTranslation('translations')

  return (
    <div className="grid h-screen place-items-center" >
      <div className="p-12 card border rounded-md text-center">
        <h1 className="text-3xl font-bold text-center pb-3">{t('students.homework_solutions_view.header')}</h1>
        <div>Już przesłane</div>
        {
          map(homeworkSolution?.solution_files, (file: string) => <a href={file}>Link do pliku</a>)
        }
      </div>
    </div>
  )
}

export default StudentHomeworkSolutionView
