import React, { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const MobileMainBar = (): ReactElement => {
  const navigate = useNavigate()
  const { t } = useTranslation('translations')

  const currentPageTranslation = (): string | undefined => {
    const pathSegments = location.pathname.split('/')

    const currentPage = Number(pathSegments.at(-1)).toString() !== pathSegments.at(-1) ? pathSegments.at(-1) : pathSegments.at(-2)
    const translation = location.pathname.startsWith('/admin') ? `breadcrumbs.admin.${currentPage}` : `breadcrumbs.${currentPage}`

    return t(translation)
  }

  return (
    <div className="fixed top-0 w-full bg-white h-[66px] flex items-center shadow-md border-b border-gray-200 px-4 z-50">
      <div className="ml-4 w-full text-xl flex font-black text-gray-900 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
          className="h-8 w-8 mr-2 "
          onClick={() => { navigate(-1) }}
        >
          <path fillRule="evenodd" d="M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
        </svg>

        <div className="flex items-center">
          {
            location.pathname.startsWith('/admin') && (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-6 w-6">
                <path fillRule="evenodd" d="M2 4.25A2.25 2.25 0 0 1 4.25 2h11.5A2.25 2.25 0 0 1 18 4.25v8.5A2.25 2.25 0 0 1 15.75 15h-3.105a3.501 3.501 0 0 0 1.1 1.677A.75.75 0 0 1 13.26 18H6.74a.75.75 0 0 1-.484-1.323A3.501 3.501 0 0 0 7.355 15H4.25A2.25 2.25 0 0 1 2 12.75v-8.5Zm1.5 0a.75.75 0 0 1 .75-.75h11.5a.75.75 0 0 1 .75.75v7.5a.75.75 0 0 1-.75.75H4.25a.75.75 0 0 1-.75-.75v-7.5Z" clipRule="evenodd" />
              </svg>
            )
          }

          <div className="ml-3">
            {currentPageTranslation()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileMainBar
