import React, { type ReactElement, useEffect, useState } from 'react'
import { map } from 'underscore'
import { useApiPost } from '../../../hooks/api/useApiPost'
import { useApiFetch } from '../../../hooks/api/useApiFetch'
import { useParseDate } from '../../../hooks/useParseDate'
import { TEACHER_HOMEWORKS_PATH } from '../../../constants/teacherRoutes'
import { API_TEACHER_LESSONS_PATH } from '../../../constants/apiRoutes'
import { useTranslation } from 'react-i18next'
import { type LessonData } from '../../../constants/types'
import DropDownIcon from '../../../assets/icons/dropdown.svg'
import AttachmentsFileInput from '../../common/inputs/AttachmentsFileInput'

const TeacherHomeworkForm = ({ url, homework, method = 'post' }: { url: string, homework?: Record<string, any> | null, method?: string }): ReactElement => {
  const { setInput, sendDataWithFiles, validationErrors, input } = useApiPost({ url, redirect: TEACHER_HOMEWORKS_PATH, successFlashMessage: 'Pomyślnie zapisano' })
  const { data: lessons } = useApiFetch({ url: API_TEACHER_LESSONS_PATH })
  const { t } = useTranslation('translations')
  const [fileRequired, setFileRequired] = useState(false)
  const { toInputDate } = useParseDate()

  useEffect(() => {
    const initialInput = {
      required: false,
      graded: true,
      file_required: false
    }

    if (homework) {
      setInput({
        ...homework,
        lesson_id: homework.lesson.id
      })
    } else {
      setInput(initialInput)
    }
  }, [homework])

  const saveHomework = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()
    await sendDataWithFiles({ fileKeys: ['files_to_add', 'files_to_remove'], method })
  }

  return (
    <form onSubmit={saveHomework}>
      <div className='grid grid-cols-1 lg:grid-cols-2 pb-20 lg:pb-0'>
        <div>
          <label className="text-lg font-medium ml-1">{t('teachers.homeworks.inputs.lesson')}</label>
          <br />
          <div className='relative w-full lg:w-2/3'>
            <div className="flex items-center border border-gray-300 rounded py-0.5 mt-3 mb-3">
              <select
                value={input.lesson_id}
                onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, lesson_id: e.target.value })) }}
                className="text-lg bg-transparent pr-10 py-2 pl-4 w-full cursor-pointer outline-none appearance-none"
              >
                <option value="">{t('teachers.homeworks.inputs.lesson_placeholder')}</option>
                {map(lessons, (lesson: LessonData) => (
                  <option key={lesson.id} value={lesson.id}>
                    {lesson?.class_units?.map(unit => unit.name).join(', ') + ' / ' + lesson.subject.name}
                  </option>
                ))}
              </select>
              <div className="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none mr-5">
                <img src={DropDownIcon} alt="DropDown"/>
              </div>
            </div>
            <div className="text-sm text-red-500">
              {map(validationErrors.lesson_id, (error: string): string => `${t('teachers.homeworks.inputs.lesson')} ${error}`).join(', ')}
            </div>
          </div>

          <br />

          <label className="text-lg font-medium ml-1">{t('teachers.homeworks.inputs.title')}</label>
          <br />
          <input
            value={input.title}
            className='px-2 py-1 border border-gray-300 rounded-lg mb-3 mt-3 h-12 w-full lg:w-5/6'
            onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, title: e.target.value })) }}
          />
          <div className="text-sm text-red-500">
            {map(validationErrors.title, (error: string): string => `${t('teachers.homeworks.inputs.title')} ${error}`).join(', ')}
          </div>

          <br />

          <label className="text-lg font-medium ml-1">{t('teachers.homeworks.inputs.description')}</label>
          <br />
          <textarea
            rows={6}
            value={input.description}
            className='px-2 py-1 border border-gray-300 rounded-lg mt-3 mb-3 py-2 resize-none w-full lg:w-5/6'
            onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, description: e.target.value })) }}
          />
          <div className="text-sm text-red-500">
            {map(validationErrors.description, (error: string): string => `${t('teachers.homeworks.inputs.description')} ${error}`).join(', ')}
          </div>
          <br />

          <label className="text-lg font-medium ml-1">{t('teachers.homeworks.inputs.deadline')}</label>
          <br />
          <div className="pb-10">
            <input
              type="date"
              value={toInputDate(input.deadline)}
              className="bg-gray-50 border border-gray-300 rounded-lg shadow-sm mt-2 px-5 py-4 w-2/3"
              onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, deadline: e.target.value })) }}
            />
            <div className="text-sm text-red-500">
              {map(validationErrors.deadline, (error: string): string => `${t('teachers.homeworks.inputs.deadline')} ${error}`).join(', ')}
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <label className="text-lg font-medium text-gray-700 w-2/5">{t('teachers.homeworks.inputs.required')}</label>
            <div className="flex items-center px-4">
              <input
                type="radio"
                value="true"
                name="requiredBooleanChoice"
                checked={input.required}
                className="form-radio cursor-pointer h-4 w-4 border-gray-700"
                onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, required: true })) }}
              />
              <label className="ml-2 cursor-pointer">Tak</label>
            </div>

            <div className="flex items-center">
              <input
                type="radio"
                value="false"
                name="requiredBooleanChoice"
                checked={!input.required}
                className="form-radio cursor-pointer h-4 w-4 border-gray-700"
                onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, required: false })) }}
              />
              <label className="ml-2 cursor-pointer">Nie</label>
            </div>
          </div>

          <br />

          <div className="flex items-center space-x-4">
            <label className="text-lg font-medium text-gray-700 w-2/5">{t('teachers.homeworks.inputs.graded')}</label>
            <div className="flex items-center px-4">
              <input
                type="radio"
                value="true"
                name="gradedBooleanChoice"
                checked={input.graded}
                className="form-radio cursor-pointer h-4 w-4 border-gray-700"
                onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, graded: true })) }}
              />
              <label className="ml-2 cursor-pointer">Tak</label>
            </div>

            <div className="flex items-center">
              <input
                type="radio"
                value="false"
                name="gradedBooleanChoice"
                checked={!input.graded}
                className="form-radio cursor-pointer h-4 w-4 border-gray-700"
                onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, graded: false })) }}
              />
              <label className="ml-2 cursor-pointer">Nie</label>
            </div>
          </div>
        </div>

        <div className='pt-20 lg:pl-28 lg:pt-0'>
          <label className="block text-lg font-medium text-gray-700">{t('teachers.homeworks.inputs.files')}</label>
          <div className='pb-6 mt-6 lg:ml-2'>
            <AttachmentsFileInput defaultInput={input.files} setInput={setInput} inputAddKey="files_to_add" inputRemoveKey="files_to_remove" />
            <div className="text-sm text-red-500 mt-3">
              {map(validationErrors.files_to_add, (error: string): string => `${t('teachers.homeworks.inputs.files')} ${error}`).join(', ')}
              {map(validationErrors.files_to_remove, (error: string): string => `${t('teachers.homeworks.inputs.files')} ${error}`).join(', ')}
            </div>
          </div>
          <br />
          <div className='flex flex-col px-8 py-10 mt-6 lg:ml-2 border border-gray-300 rounded-lg'>
            <label className="inline-grid grid-cols-6 items-center cursor-pointer pl-2">
              <input
                type="checkbox"
                checked={input.file_required}
                className="sr-only peer col-span-1"
                onChange={(e) => {
                  setInput((prevInput: any) => ({ ...prevInput, file_required: !input.file_required }))
                }}
              />
              <div
                className="relative w-11 h-6 bg-gray-200 ring-2 ring-gray-500 rounded-full
                           peer peer-checked:ring-2 peer-checked:ring-blue-600 dark:bg-gray-500
                           peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full
                           peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px]
                           after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full
                           after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                onClick={() => { setFileRequired(!fileRequired) }}
              >
              </div>
              <div className="col-span-1 block lg:hidden"></div>
              <span className='text-base text-gray-600 col-span-4 lg:col-span-5'>
                {t('teachers.homeworks.inputs.add_file_required')}
              </span>
            </label>

            <div className="pt-9">
              <label htmlFor="deadline" className="text-xl text-gray-700">
                {t('teachers.homeworks.inputs.file_upload_deadline')}
              </label>
              <div className="pt-3 text-gray-500">
                {t('teachers.homeworks.inputs.file_upload_tip')}
              </div>
              <input
                type="date"
                value={toInputDate(input.file_upload_deadline)}
                className={`bg-gray-50 border ${input.file_required === true ? 'border-gray-300' : 'border-gray-200 text-gray-400'} rounded-lg shadow-sm mt-2 px-5 py-4 w-full`}
                disabled={!input.file_required}
                onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, file_upload_deadline: e.target.value })) }}
              />
              <div className="text-sm text-red-500">
                {map(validationErrors.file_upload_deadline, (error: string): string => `${t('teachers.homeworks.inputs.file_upload_deadline')} ${error}`).join(', ')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid place-items-end pb-32">
        <button type="submit" className='bg-blue-500 hover:bg-blue-700 text-white py-3 px-6 rounded md:mt-10'>
          {homework ? t('teachers.homeworks.inputs.update') : t('teachers.homeworks.inputs.submit')}
        </button>
      </div>
    </form>
  )
}

export default TeacherHomeworkForm
