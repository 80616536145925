import React, { type ReactElement } from 'react'
import { STUDENT_HOMEWORKS_PATH } from '../../../constants/studentRoutes'
import { map, isEmpty } from 'underscore'
import { useTranslation } from 'react-i18next'
import { useApiPost } from '../../../hooks/api/useApiPost'
import { useParams } from 'react-router-dom'
import { API_STUDENT_HOMEWORK_SOLUTIONS_PATH } from '../../../constants/apiRoutes'

const StudentHomeworkSolutionForm = (): ReactElement => {
  const queryParams = useParams()
  const apiSolutionUrl = API_STUDENT_HOMEWORK_SOLUTIONS_PATH.replace(':homeworkId', queryParams.homeworkId as string)
  const { setInput, input, sendDataWithFiles, validationErrors } = useApiPost({ url: apiSolutionUrl, redirect: STUDENT_HOMEWORKS_PATH })
  const { t } = useTranslation('translations')

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()

    await sendDataWithFiles({ fileKeys: ['solution_files'] })
  }

  const handleAutonomousWorkAcceptedAt = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const autonomousWorkAcceptedAt = e.target.checked ? new Date().toJSON() : null

    setInput((prevInput: any) => ({ ...prevInput, autonomous_work_accepted_at: autonomousWorkAcceptedAt }))
  }

  return (
    <form onSubmit={handleSubmit}>
      <br />
      <label>{t('labels.file_list')}</label>
      <br />
      <input
        type="file"
        multiple={true}
        className="px-2 py-1 border border-gray-300 rounded-md"
        onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, solution_files: Array.from(e.target.files ?? []) })) }}
      />
      <div className="text-sm text-red-500">
        {map(validationErrors.solution_files, (error: string): string => `Pliki ${error}`).join(', ')}
      </div>
      <br />
      <input
        type="checkbox"
        onChange={handleAutonomousWorkAcceptedAt}
      />
      <label className="ml-2">{t('labels.autonomous_work_accepted')}</label>
      <div className="text-sm text-red-500">
        {map(validationErrors.autonomous_work_accepted_at, (error: string): string => `Akceptacja ${error}`).join(', ')}
      </div>
      <br />
      <div className="flex justify-center pt-3">
        <button
          type="submit"
          className="bg-blue-400 rounded-md text-white p-2"
          disabled={isEmpty(input)}
        >
          {t('students.homework_solutions_view.submit_button')}
        </button>
      </div>
    </form>
  )
}

export default StudentHomeworkSolutionForm
