import React, { type ReactElement } from 'react'

const UserProfileView = (): ReactElement => {
  return (
    <div className="grid h-screen place-items-center" >
      To Be Added
    </div>
  )
}

export default UserProfileView
