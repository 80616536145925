import React, { type ReactElement, useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import FileUploadIcon from '../../../assets/icons/file_upload.svg'
import FileIcon from '../../../assets/icons/file.svg'
import TrashCanIcon from '../../../assets/icons/trashcan.svg'
import { map } from 'underscore'

const AttachmentsFileInput = (
  { defaultInput, setInput, inputAddKey, inputRemoveKey }: { setInput: (prevInput: any) => any, defaultInput: Array<Record<string, any>>, inputAddKey: string, inputRemoveKey: string }
): ReactElement => {
  const [inputFiles, setInputFiles] = useState<File[] | Array<Record<string, any>>>([])

  useEffect(() => {
    if (defaultInput) setInputFiles(defaultInput)
  }, [defaultInput])

  const onDrop = useCallback((acceptedFiles: any) => {
    setInputFiles(currentInputFiles => {
      const updatedFiles = [...currentInputFiles, ...acceptedFiles].slice(0, 6)
      setInput((prevInput: any) => ({ ...prevInput, [inputAddKey]: updatedFiles }))
      return updatedFiles
    })
  }, [setInput, inputAddKey])

  const removeFile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, fileToRemove: any): void => {
    e.preventDefault()
    e.stopPropagation()
    setInputFiles(currentFiles => currentFiles.filter(file => file !== fileToRemove))

    if (fileToRemove.id) {
      setInput((prevInput: any) => ({
        ...prevInput,
        [inputRemoveKey]: [...(prevInput[inputRemoveKey] || []), fileToRemove.id]
      }))
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    maxSize: 10 * 1024 * 1024
  })

  const UploadZone = (): ReactElement => {
    return (
      <>
        <div className='hidden lg:block'>
          <div className='flex place-content-center'>
            <img src={FileUploadIcon} alt='file_upload.svg' />
          </div>
          <br />
          <div className='text-center'>
            <div className='text-lg'>Przeciągnij i upuść</div>
            <br />
            <div className='text-sm'>lub</div>
            <br />
            <div className='grid place-items-center'>
              <div className='button bg-blue-500 hover:bg-blue-700 text-white py-3 px-6 rounded w-2/5'>Dodaj pliki</div>
            </div>
          </div>
        </div>
        <div className='block lg:hidden'>
          <div className="grid place-items-center">
            <div className='button bg-blue-500 hover:bg-blue-700 text-white py-3 px-6 rounded w-full'>
              <div className="inline-flex mx-12">
                <img src={FileUploadIcon} alt='file_upload.svg' />
                <div className='mx-8 mt-1'>
                  Dodaj pliki
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div {...getRootProps()} className='min-h-[50px] lg:min-h-[200px] w-full cursor-pointer items-center justify-center lg:rounded-md lg:border lg:border-gray-300 lg:p-6'>
        <input {...getInputProps()} />
        <UploadZone />
      </div>
      <div className='grid gap-2 max-w-full pt-2'>
        {map(inputFiles, (file: any, index) => (
          <div key={index} className='grid grid-cols-4 max-w-full h-[50px] cursor-pointer rounded-md border border-gray-300 p-1 items-center relative group'>
            <div className='flex col-span-3 mx-3'>
              <img src={FileIcon} alt='file' className='w-6 h-6 mr-2' />
              <p className='flex truncate text-xs items-center'>{file.name || file.filename}</p>
            </div>
            <div className='flex col-span-1 items-center justify-end mx-3'>
              <button onClick={async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => { removeFile(e, file) }}>
                <img src={TrashCanIcon} alt='close' className='w-6 h-6' />
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default AttachmentsFileInput
