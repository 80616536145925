import React, { type ReactElement, useState, useEffect, useContext } from 'react'
import { Breadcrumbs } from '@mui/material'
import DropdownIcon from '../../assets/icons/dropdown.svg'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { map, filter } from 'underscore'
import { LessonViewContext } from '../../contexts/common/LessonViewContext'

const NavigationBreadcrumbs = (): ReactElement => {
  const [crumbs, setCrumbs] = useState<string[]>([])
  const { t } = useTranslation('translations')
  const location = useLocation()
  const skippedCrumbs = ['students', 'teachers', '', 'new']
  const { lessonSubjectAndUnits } = useContext(LessonViewContext)

  useEffect(() => {
    const filtered = filter(location.pathname.split('/'), (crumb: string) => {
      // Second check is if crumb is a number as string, i.e: '9'
      return !skippedCrumbs.includes(crumb) && Number(crumb).toString() !== crumb
    })

    const pathSegments = location.pathname.split('/')
    const lessonsIndex = pathSegments.indexOf('lessons')
    if (lessonsIndex !== -1 && lessonsIndex < pathSegments.length - 1 && !isNaN(Number(pathSegments[lessonsIndex + 1]))) {
      const updatedCrumbs = [
        ...filtered.slice(0, lessonsIndex + 1),
        lessonSubjectAndUnits,
        ...filtered.slice(lessonsIndex + 2)
      ]
      setCrumbs(updatedCrumbs)
    } else {
      setCrumbs(filtered)
    }
  }, [location, lessonSubjectAndUnits])

  if (location.pathname.startsWith('/admin')) return <></>

  return (
    <div className="w-full flex items-center justify-left">
      <div className="pt-10 pb-2 px-14 font-['DM Sans']">
        <Breadcrumbs separator={<img src={DropdownIcon} className="rotate-[270deg] h-[7px] px-3 object-scale-down" />}>
          <div className="flex gap-4 inline-block">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 17 16" fill="none">
              <path d="M2.5 6.00016L8.5 1.3335L14.5 6.00016V13.3335C14.5 13.6871 14.3595 14.0263 14.1095 14.2763C13.8594 14.5264 13.5203 14.6668 13.1667 14.6668H3.83333C3.47971 14.6668 3.14057 14.5264 2.89052 14.2763C2.64048 14.0263 2.5 13.6871 2.5 13.3335V6.00016Z" stroke="#2D2D2D" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M6.5 14.6667V8H10.5V14.6667" stroke="#2D2D2D" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            {t('breadcrumbs.home')}
          </div>
          {
            map(crumbs, (crumb: string): ReactElement => {
              const translation = t(`breadcrumbs.${crumb}`)
              return <div key={crumb}>{translation === `breadcrumbs.${crumb}` ? crumb : translation}</div>
            })
          }
        </Breadcrumbs>
      </div>
    </div>
  )
}

export default NavigationBreadcrumbs
