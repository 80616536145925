import { type AxiosError } from 'axios'
import { useContext } from 'react'
import { FlashContext } from '../../contexts/flash/FlashContext'
import { CurrentUserContext } from '../../contexts/users/CurrentUserContext'
import { FlashType } from '../../constants/types'
import GetRootFromRoleService from '../../services/users/GetRootFromRoleService'
import { useNavigate } from 'react-router-dom'

export const useHandleApiError = (
  errorMessage = 'Coś poszło nie tak'
): (error: any) => void => {
  const { setFlash } = useContext(FlashContext)
  const { currentUser } = useContext(CurrentUserContext)
  const navigate = useNavigate()

  const handleError = (error: AxiosError): void => {
    const root = GetRootFromRoleService.call(currentUser)

    switch (error?.response?.status) {
    case 403:
      setFlash(() => (
        {
          // FIXME: Translations
          message: 'Jesteś nieuprawniony do wykonania tej akcji',
          type: FlashType.error
        }
      ))
      navigate(root)
      break
    case 401:
      break
    default:
      setFlash(() => ({
        // FIXME: Translations
        message: errorMessage,
        type: FlashType.error
      }))
      navigate(root)
      break
    }
  }

  return handleError
}
