import React, { type ReactElement, useContext } from 'react'
import { useParseDate } from '../../hooks/useParseDate'
import { useTranslation } from 'react-i18next'
import Dropdown, { DropdownHead, DropdownItem, DropdownMenu } from '../common/Dropdown'
import { CurrentUserContext } from '../../contexts/users/CurrentUserContext'
import { type CommentData } from '../../constants/types'
import DefaultAvatar from '../../assets/default_avatar.png'

const CommentReplyItem = ({ reply, deleteComment }: { reply: CommentData, deleteComment: () => Promise<void> }): ReactElement => {
  const { toShortHumanizedDate } = useParseDate()
  const { t } = useTranslation('translations')
  const { currentUser } = useContext(CurrentUserContext)

  return (
    <article className="p-6 ml-6 lg:ml-12 text-base bg-white rounded-lg ">
      <footer className="flex justify-between items-center mb-2">
        <div className="flex items-center">
          <p className="inline-flex items-center mr-3 text-sm text-gray-900 font-semibold">
            <img
              className="mr-2 w-6 h-6 rounded-full"
              src={reply.written_by.avatar?.url ?? DefaultAvatar}
              alt="avatar" />
            {reply.written_by.name}
          </p>
          <p className="text-sm text-gray-600">
            <time dateTime={reply.created_at.toString()}>{toShortHumanizedDate(reply.created_at)}</time>
          </p>
        </div>
        {
          currentUser?.admin && (
            <Dropdown>
              <DropdownHead>
                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                  viewBox="0 0 16 3">
                  <path
                    d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"/>
                </svg>
              </DropdownHead>
              <DropdownMenu>
                <DropdownItem action={deleteComment} text={t('comments.delete')}/>
              </DropdownMenu>
            </Dropdown>
          )
        }
      </footer>
      <p className="text-gray-500">{reply.body}</p>
    </article>
  )
}

export default CommentReplyItem
