import React, { type ReactElement, useContext, useState, useEffect } from 'react'
import { map, omit } from 'underscore'
import { type HomeworkData, type UseApiGetReturnParams, type HomeworksCounters } from '../../constants/types'
import { CurrentUserContext } from '../../contexts/users/CurrentUserContext'
import { TEACHER_ROLE } from '../../constants/roles'
import { TEACHER_NEW_HOMEWORK_PATH } from '../../constants/teacherRoutes'
import { useTranslation } from 'react-i18next'
import HomeworkListItem from './HomeworkListItem'
import Pagination from '../common/Pagination'
import circlePlusIcon from '../../assets/icons/plus-circle.svg'
import { Link } from 'react-router-dom'
import TabFilter from '../../components/filters/tabs/TabFilter'
import HSSelect from '@preline/select'

const HomeworksList = (
  { homeworks, paginationMeta, fetchData }:
  { homeworks: HomeworkData[], paginationMeta: Record<string, any>, fetchData: UseApiGetReturnParams['fetchData'] }
): ReactElement => {
  const { t } = useTranslation('translations')
  const { currentUser } = useContext(CurrentUserContext)
  const [sortParams, setSortParams] = useState<{ sort_by: string, sort_order: string }>({ sort_by: '', sort_order: '' })
  const [filters, setFilters] = useState<{ sent: string }>({ sent: '' })
  const [counters, setCounters] = useState({} as HomeworksCounters)

  useEffect(() => {
    fetchData({ ...sortParams, ...filters }).then((result: any) => {
      setCounters(result?.meta?.counters)
    })
    HSSelect.autoInit()
  }, [sortParams, filters])

  const handleSort = (field: string, order: string): void => {
    setSortParams({ sort_by: field, sort_order: order })
  }

  const handleTabSetFilters = (value: string | null): void => {
    if (value) {
      setFilters((prevFilters: any) => ({ ...prevFilters, sent: value }))
    } else {
      setFilters((prevFilters: any) => omit(prevFilters, 'sent'))
    }
  }

  return (
    <div className="w-full flex items-center justify-center">
      <div className="w-full pb-10 pt-5 lg:px-14 px-5">
        <div className="flex justify-between pb-8 pt-4 lg:block hidden">
          <div className="text-neutral-800 text-[32px] font-semibold font-['DM Sans'] leading-[48px] tracking-tight">
            {t('homeworks.h1')}
          </div>
          {currentUser?.role === TEACHER_ROLE &&
          <div className="bg-blue-500 hover:bg-blue-700 text-white py-3 px-5 rounded md:w-fit">
            <Link
              to={TEACHER_NEW_HOMEWORK_PATH}
              className="flex items-center"
            >
              {t('homeworks.teachers.add_new_homework')} <img src={circlePlusIcon} className="h-5 w-auto ml-2 background-white" />
            </Link>
          </div>
          }
        </div>

        <div className="justify-start items-center gap-3 inline-flex mb-6">
          <div className="h-fit p-1 bg-white rounded-xl justify-start items-start gap-2.5 flex w-full shadow-md">
            <TabFilter
              setFilters={() => { handleTabSetFilters(null) } }
              text='Wszystkie'
              counter={counters?.sum}
              active={!filters.sent}
            />
            <TabFilter
              setFilters={() => { handleTabSetFilters('true') } }
              text='Wysłane'
              counter={counters?.sent}
              active={filters.sent === 'true'}
            />
            <TabFilter
              setFilters={() => { handleTabSetFilters('false') } }
              text='Niewysłane'
              counter={counters?.not_sent}
              active={filters.sent === 'false'}
            />
          </div>
        </div>

        <div className="lg:hidden block mb-10">
          <div className="relative">
            <select data-hs-select='{
                "placeholder": "Sortuj według...",
                "toggleTag": "<button type=\"button\" aria-expanded=\"false\"></button>",
                "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 ps-4 pe-9 flex gap-x-2 text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:outline-none focus:ring-2 focus:ring-blue-500",
                "dropdownClasses": "mt-2 z-50 w-full max-h-72 p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto",
                "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100",
                "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"shrink-0 size-3.5 text-blue-600 \" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" strokeWidth=\"2\" strokeLinecap=\"round\" strokeLinejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
            }'
            onChange={(e) => {
              const [field, order] = e.target.value.split('-')
              handleSort(field, order)
            }}
            defaultValue=""
            >
              <option value="" disabled>{t('Sortuj według...')}</option>
              <option value="subject_name-asc">{t('Przedmiot rosnąco')}</option>
              <option value="subject_name-desc">{t('Przedmiot malejąco')}</option>
              <option value="topic-asc">{t('Temat rosnąco')}</option>
              <option value="topic-desc">{t('Temat malejąco')}</option>
              <option value="deadline-asc">{t('Data zakończenia rosnąco')}</option>
              <option value="deadline-desc">{t('Data zakończenia malejąco')}</option>
            </select>

            <div className="absolute top-1/2 end-2.5 -translate-y-1/2">
              <svg className="shrink-0 h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="m7 15 5 5 5-5"></path>
                <path d="m7 9 5-5 5 5"></path>
              </svg>
            </div>
          </div>
        </div>

        <div className="rounded-md shadow-md border border-gray-200">
          <div className="card grid grid-cols-12 border border-gray-100 border-b-gray-300 bg-gray-100 py-3 px-5 bg-[#F9FAFB] text-sm text-gray-500 rounded-t-md">
            <div className="col-span-2 lg:block hidden">
              <div className="flex space-x-2" onClick={() => { handleSort('subject_name', sortParams.sort_order === 'asc' ? 'desc' : 'asc') }}>
                <div className="font-['DM Sans']">
                  {t('homeworks.heading.lesson')}
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 hover:cursor-pointer">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                </svg>
              </div>
            </div>
            <div className="col-span-3 lg:block hidden">
              <div className="flex space-x-2" onClick={() => { handleSort('title', sortParams.sort_order === 'asc' ? 'desc' : 'asc') }}>
                <div className="font-['DM Sans']">
                  {t('homeworks.heading.topic')}
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 hover:cursor-pointer">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                </svg>
              </div>
            </div>
            <div className="col-span-2 lg:block hidden">
              <div className="font-['DM Sans']">
                {t('homeworks.heading.status')}
              </div>
            </div>
            <div className="col-span-2 lg:block hidden">
              <div className="font-['DM Sans']">
                {t('homeworks.heading.sent')}
              </div>
            </div>
            <div className="col-span-2 lg:block hidden">
              <div className="flex space-x-2" onClick={() => { handleSort('deadline', sortParams.sort_order === 'asc' ? 'desc' : 'asc') }}>
                <div className="font-['DM Sans']">
                  {t('homeworks.heading.deadline')}
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 hover:cursor-pointer">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                </svg>
              </div>
            </div>
            <div className="font-['Inter'] col-span-1 lg:block hidden">{t('homeworks.heading.file')}</div>
            <div className="col-span-12">
              <div className="font-['Inter'] font-extrabold lg:hidden block text-xl">
                {t('homeworks.h1')}
              </div>
            </div>
          </div>
          <div className="divide-y">
            {
              map(homeworks, (homework: HomeworkData): ReactElement => {
                return <HomeworkListItem homework={homework} key={homework.id} />
              })
            }
          </div>
          <div className="p-4 rounded-b-lg border border-transparent border-t-gray-200">
            <Pagination paginationMeta={paginationMeta} fetchData={fetchData} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeworksList
