import React, { type ReactElement, useContext } from 'react'
import { type HomeworkData } from '../../constants/types'
import { STUDENT_ROLE, TEACHER_ROLE } from '../../constants/roles'
import { useRolePath } from '../../hooks/useRolePath'
import { useTranslation } from 'react-i18next'
import { useParseDate } from '../../hooks/useParseDate'
import { Link } from 'react-router-dom'
import { STUDENT_NEW_HOMEWORK_SOLUTION_PATH, STUDENT_HOMEWORK_SOLUTION_PATH } from '../../constants/studentRoutes'
import { TEACHER_HOMEWORK_EDIT_PATH } from '../../constants/teacherRoutes'
import { CurrentUserContext } from '../../contexts/users/CurrentUserContext'
import { LESSON_PATH } from '../../constants/rolePaths'

const HomeworkListItem = ({ homework }: { homework: HomeworkData }): ReactElement => {
  const { t } = useTranslation('translations')
  const { currentUser } = useContext(CurrentUserContext)
  const { toLocalDate } = useParseDate()
  const rolePath = useRolePath(LESSON_PATH, currentUser)
  const lessonViewPath = homework?.lesson?.id
    ? rolePath.replace(':id', homework.lesson.id.toString())
    : ''

  const homeworkSolutionPath = (): string => {
    if (solutionSubmitted()) {
      return (
        STUDENT_HOMEWORK_SOLUTION_PATH.replace(':homeworkId', homework.id.toString()).replace(':id', homework.homework_solutions[0].id.toString())
      )
    }

    return STUDENT_NEW_HOMEWORK_SOLUTION_PATH.replace(':homeworkId', homework.id.toString())
  }

  const homeworkSolutionButtonLabel = (): ReactElement => {
    if (solutionSubmitted()) {
      return (
        <div>
          {t('homeworks.students.edit_solution')}
        </div>
      )
    }

    return (
      <div className="flex gap-1">
        {t('homeworks.students.add_solution')}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
        </svg>
      </div>
    )
  }

  const homeworkEditPath = (): string => {
    return TEACHER_HOMEWORK_EDIT_PATH.replace(':id', homework.id.toString())
  }

  const afterDeadline = (): boolean => {
    return new Date(homework.deadline) < new Date()
  }

  const solutionSubmitted = (): boolean => {
    return homework.homework_solutions.length > 0
  }

  const canEditSolution = (): boolean => {
    return currentUser?.role === STUDENT_ROLE && (!afterDeadline() || solutionSubmitted())
  }

  return (
    <div className="card grid grid-cols-4 lg:grid-cols-12 py-3 px-5 lg:h-16 text-sm flex items-center">
      <div className="col-span-2 hidden lg:block">
        {homework?.lesson?.subject.name}
      </div>
      <div className="col-span-3 opacity-50 hidden lg:block">
        {homework.title}
      </div>
      {afterDeadline()
        ? (
          <div className="lg:col-span-2 bg-gray-200 py-1 px-2 rounded-lg w-max hidden lg:block">
            <div className="text-gray-600">
              {t('homeworks.solution_finished')}
            </div>
          </div>
        )
        : (
          <div className="lg:col-span-2 bg-green-200 py-1 px-2 rounded-lg w-max hidden lg:block">
            <div className="text-green-900">
              {t('homeworks.solution_active')}
            </div>
          </div>
        )
      }
      <div className="col-span-2 hidden lg:block">
        {solutionSubmitted()
          ? (
            <div className="lg:col-span-2 bg-green-200 py-[1px] px-[1px] rounded-md w-max hidden lg:block">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2d1453" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
              </svg>
            </div>
          )
          : (
            <div className="lg:col-span-2 bg-red-200 py-[1px] px-[1px] rounded-md w-max hidden lg:block">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#8B1211" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </div>
          )
        }
      </div>
      <div className="col-span-2 opacity-50 hidden lg:block">
        {toLocalDate(homework.deadline)}
      </div>
      <div className="col-span-3 block lg:hidden">
        <Link
          to={lessonViewPath}
          className="text-blue-600 hover:text-blue-900 text-xl"
        >
          {homework?.lesson?.subject.name}
        </Link>
        <div className="opacity-50">
          {homework?.title}
        </div>
        <div className="mt-3 mb-3 opacity-50">
          {toLocalDate(homework?.deadline)}
        </div>
        <div className="flex gap-2">
          {afterDeadline()
            ? (
              <div className="lg:col-span-2 bg-gray-200 py-1 px-2 rounded-lg w-max lg:hidden">
                <div className="text-gray-600">
                  {t('homeworks.solution_finished')}
                </div>
              </div>
            )
            : (
              <div className="lg:col-span-2 bg-green-200 py-1 px-2 rounded-lg w-max lg:hidden">
                <div className="text-green-900">
                  {t('homeworks.solution_active')}
                </div>
              </div>
            )
          }
          {solutionSubmitted()
            ? (
              <div className="lg:col-span-2 bg-green-200 rounded-md w-7 block lg:hidden items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2d1453" className="w-7 h-7">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                </svg>
              </div>
            )
            : (
              <div className="lg:col-span-2 bg-red-200 rounded-md w-7 block lg:hidden items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#8B1211" className="w-7 h-7">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
              </div>
            )
          }
        </div>
      </div>
      <div className="col-span-1">
        {canEditSolution() &&
          (
            <Link
              to={homeworkSolutionPath()}
              className="text-blue-800 hover:text-white flex justify-center w-24 py-2 px-3 border border-blue-800 rounded-lg hover:bg-blue-800"
            >
              {homeworkSolutionButtonLabel()}
            </Link>
          )
        }
        {(currentUser?.role === TEACHER_ROLE) &&
          (
            <Link
              to={homeworkEditPath()}
              className="px-4 py-1 w-max border border-1 border-[#3758F9] rounded-2xl text-[#3758F9]"
            >
              {t('homeworks.teachers.edit_homework')}
            </Link>
          )
        }
      </div>
    </div>
  )
}

export default HomeworkListItem
