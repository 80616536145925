import React, { type ReactElement, useContext, useEffect } from 'react'
import DefaultAvatar from '../../../assets/default_avatar.png'
import LogoutIcon from '../../../assets/icons/logout.svg'
import { useTranslation } from 'react-i18next'
import { CurrentUserContext } from '../../../contexts/users/CurrentUserContext'
import { MobileMenuContext } from '../../../contexts/common/MobileMenuContext'
import { NotReadRequiredNewsContext } from '../../../contexts/news/NotReadRequiredNewsContext'
import { NEWS_PATH, MESSAGES_PATH } from '../../../constants/routes'
import { DASHBOARD_PATH } from '../../../constants/rolePaths'
import { ADMIN_DASHBOARD_PATH } from '../../../constants/adminRoutes'
import NavbarLink from './NavbarLink'
import { Link } from 'react-router-dom'
import { STUDENT_ROLE, TEACHER_ROLE, PARENT_ROLE } from '../../../constants/roles'
import { useApiFetch } from '../../../hooks/api/useApiFetch'
import { API_NEWS_NOT_READ_REQUIRED_PATH, API_SEARCHES_PATH } from '../../../constants/apiRoutes'
import SearchBar from '../SearchBar'

const MainBar = (): ReactElement => {
  const { t } = useTranslation('translations')
  const { fetchData: fetchNotReadNews } = useApiFetch({ url: API_NEWS_NOT_READ_REQUIRED_PATH })

  const { notReadRequiredNewsCount, setNotReadRequiredNewsCount } = useContext(NotReadRequiredNewsContext)
  const { currentUser, logout } = useContext(CurrentUserContext)
  const { setRenderMobileMenu } = useContext(MobileMenuContext)

  const iconStroke = currentUser?.role === STUDENT_ROLE ? 'currentColor' : 'white'
  const textColor = currentUser?.role === STUDENT_ROLE ? '' : 'text-white'

  const handleButtonClick = (): void => {
    if (window.matchMedia('(min-width: 1024px)').matches) {
      logout()
    } else {
      setRenderMobileMenu(prev => !prev)
    }
  }

  useEffect(() => {
    const fetchNewsCount = async (): Promise<void> => {
      const newsCount = await fetchNotReadNews()
      setNotReadRequiredNewsCount(newsCount.length)
    }

    void fetchNewsCount()
  }, [notReadRequiredNewsCount])

  // FIXME: Move to constant / hook
  const backgroundColor = (): string => {
    if (currentUser?.role === TEACHER_ROLE) {
      return 'bg-blue-950'
    } else if (currentUser?.role === PARENT_ROLE) {
      return 'bg-[#084DC5]'
    } else {
      return 'bg-white'
    }
  }

  return (
    <div className={`h-[66px] ${backgroundColor()} ${textColor} border-b border-zinc-200 px-2`}>
      <div className="flex justify-between items-center h-full w-full">

        <div className="flex items-center mx-2 h-full">
          <div className="flex items-center gap-2">
            <div className={`${currentUser?.role === STUDENT_ROLE ? 'text-blue-600' : 'text-white'} text-3xl font-black`}>{t('navbar.app_name')}</div>
          </div>
          <div className="flex gap-8 ml-10 h-full">
            <NavbarLink
              path={currentUser ? DASHBOARD_PATH[currentUser.role] : '/'}
              activeTabClass="border-b-2 border-blue-600 flex items-center"
              className="flex items-center pe-1"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke={iconStroke} className="h-6 w-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
              </svg>

              <div className={`${textColor} text-base font-medium ps-2`}>
                {t('navbar.dashboard')}
              </div>
            </NavbarLink>
            <NavbarLink
              path={NEWS_PATH}
              activeTabClass="border-b-2 border-blue-600 flex items-center"
              className="flex items-center pe-1"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke={iconStroke} className="h-6 w-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z" />
              </svg>
              <div className={`${textColor} text-base font-medium font-['Inter'] leading-normal ps-2`}>
                {t('navbar.news')}
              </div>
            </NavbarLink>
          </div>
        </div>
        <div className="flex-grow px-[100px]">
          <SearchBar apiFetchResultsUrl={API_SEARCHES_PATH} />
        </div>

        <div className="flex items-center gap-6 mx-2">
          <div className="flex items-center gap-6">
            <NavbarLink path={MESSAGES_PATH} className="cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke={iconStroke} className="w-6 w-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
              </svg>

            </NavbarLink>
          </div>
          <div className="w-px h-6 bg-gray-200" />
          <div className="flex items-center gap-2">
            <img
              src={currentUser?.avatar_url ?? DefaultAvatar}
              className="object-cover cursor-pointer h-10 w-10 rounded-full"
              alt="Avatar"
            />
            <div>
              <div className="text-md">{currentUser?.name ?? 'Username'}</div>
              <div className="pt-1 text-xs opacity-50">{t(`navbar.roles.${currentUser?.role}`)}</div>
            </div>

            <div className="hs-dropdown relative inline-flex">
              <button id="hs-dropdown-transform-style" type="button" className="hs-dropdown-toggle px-2 items-center" aria-haspopup="menu" aria-expanded="false" aria-label="Dropdown">
                <svg className="hs-dropdown-open:rotate-180 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="m6 9 6 6 6-6"/></svg>
              </button>

              <div className="hs-dropdown-menu w-48 transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden z-10" role="menu" aria-orientation="vertical" aria-labelledby="hs-dropdown-transform-style">
                <div className="hs-dropdown-open:ease-in hs-dropdown-open:opacity-100 hs-dropdown-open:scale-100 transition ease-out opacity-0 scale-95 duration-200 mt-2 origin-top-left min-w-60 bg-white shadow-md rounded-lg p-2" data-hs-transition>
                  <a className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100" href="#">
                    {currentUser?.admin &&
                        <Link className="p-2 hover:bg-gray-100 w-full" to={ADMIN_DASHBOARD_PATH}>
                          {t('navbar.user_options.admin_link')}
                        </Link>
                    }
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button onClick={handleButtonClick} className="px-4">
          <picture>
            <source media="(min-width: 960px)" srcSet={LogoutIcon} />
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke={iconStroke} className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9" />
            </svg>
          </picture>
        </button>
      </div>
    </div>
  )
}

export default MainBar
