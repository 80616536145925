import React, { type ReactElement, useRef } from 'react'
import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/js/plugins/align.min.js'
import 'froala-editor/js/plugins/char_counter.min.js'
import 'froala-editor/js/plugins/code_beautifier.min.js'
import 'froala-editor/js/plugins/code_view.min.js'
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/js/plugins/emoticons.min.js'
import 'froala-editor/js/plugins/entities.min.js'
import 'froala-editor/js/plugins/file.min.js'
import 'froala-editor/js/plugins/font_family.min.js'
import 'froala-editor/js/plugins/font_size.min.js'
import 'froala-editor/js/plugins/fullscreen.min.js'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/js/plugins/image_manager.min.js'
import 'froala-editor/js/plugins/line_breaker.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/paragraph_format.min.js'
import 'froala-editor/js/plugins/paragraph_style.min.js'
import 'froala-editor/js/plugins/quote.min.js'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/js/plugins/url.min.js'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/plugins/char_counter.min.css'
import 'froala-editor/css/plugins/code_view.min.css'
import 'froala-editor/css/plugins/colors.min.css'
import 'froala-editor/css/plugins/emoticons.min.css'
import 'froala-editor/css/plugins/file.min.css'
import 'froala-editor/css/plugins/image.min.css'
import 'froala-editor/css/plugins/image_manager.min.css'
import 'froala-editor/css/plugins/line_breaker.min.css'
import 'froala-editor/css/plugins/table.min.css'
import 'froala-editor/css/themes/dark.min.css'
import FroalaEditor from 'react-froala-wysiwyg'
import { type EditorComponentProps } from '../../constants/types'
import { useTranslation } from 'react-i18next'
import { map } from 'underscore'

const EditorComponent = ({ content, setContent, fileUploadURL, validationErrors }: EditorComponentProps): ReactElement => {
  const editorRef = useRef<any>(null)
  const { t } = useTranslation('translations')

  const handleModelChange = (model: any): void => {
    setContent((prevInput: any) => ({ ...prevInput, content: model }))
  }

  const handleFileUpload = (response: any): void => {
    setContent((prevInput: any) => {
      const newId = JSON.parse(response).id
      return {
        ...prevInput,
        file_ids: [...(prevInput.file_ids || []), newId]
      }
    })
  }

  return (
    <div>
      <h2>{t('froala.content')}</h2>
      <FroalaEditor
        ref={editorRef}
        tag='textarea'
        model={content}
        onModelChange={handleModelChange}
        config={{
          placeholderText: 'Edit Your Content Here!',
          charCounterCount: true,
          toolbarButtons: [
            'fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|',
            'fontFamily', 'fontSize', 'color', 'inlineStyle', 'paragraphStyle', '|',
            'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '-',
            'insertLink', 'insertImage', 'insertFile', 'insertTable', '|',
            'emoticons', 'specialCharacters', 'insertHR', 'selectAll', 'clearFormatting', '|',
            'print', 'help', 'html', '|', 'undo', 'redo'
          ],
          pluginsEnabled: [
            'align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable',
            'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image',
            'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat',
            'paragraphStyle', 'quickInsert', 'quote', 'table', 'url'
          ],
          requestHeaders: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          },
          imageUploadURL: process.env.REACT_APP_API_URL + fileUploadURL,
          imageUploadParam: 'file',
          imageUploadMethod: 'POST',
          fileUploadURL: process.env.REACT_APP_API_URL + fileUploadURL,
          fileUploadMethod: 'POST',
          fileUploadParam: 'file',
          fileMaxSize: 20 * 1024 * 1024,
          fileAllowedTypes: [
            'jpeg', 'jpg', 'png', 'gif', 'svg', 'webp',
            'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'rtf', 'csv',
            'mp3', 'wav', 'ogg', 'm4a', 'aac',
            'mp4', 'webm', 'avi', 'mov',
            'zip', 'rar', '7z', 'json', 'xml', 'md', 'html'
          ],
          imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL', 'imageManager'],
          imageEditButtons: ['imageReplace', 'imageAlign', 'imageRemove'],
          imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
          imageMaxSize: 5 * 1024 * 1024,
          events: {
            'image.uploaded': (response: any) => { handleFileUpload(response) },
            'file.uploaded': (response: any) => { handleFileUpload(response) }
          }
        }}
      />
      <div className="text-sm text-red-500">
        {map(validationErrors.content, (error: string): string => `${t('froala.content')} ${error}`).join(', ')}
      </div>
      <div className="text-sm text-red-500">
        {map(validationErrors.file_ids, (error: string): string => `${t('froala.file_ids')} ${error}`).join(', ')}
      </div>
      <br />
    </div>
  )
}

export default EditorComponent
