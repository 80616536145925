import React, { type ReactElement, useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useRolePath } from '../../../hooks/useRolePath'
import XIcon from '../../../assets/icons/x.svg'
import { CurrentUserContext } from '../../../contexts/users/CurrentUserContext'
import { MobileMenuContext } from '../../../contexts/common/MobileMenuContext'
import { ActiveTabContext } from '../../../contexts/common/ActiveTabContext'
import { PARENT_ROLE, STUDENT_ROLE, TEACHER_ROLE } from '../../../constants/roles'
import {
  MY_CLASS_UNIT_PATH,
  NEWS_PATH,
  GRADES_PATH,
  TESTS_PATH,
  MEETINGS_PATH,
  PAYMENTS_PATH,
  MESSAGES_PATH,
  ATTENDANCE_PATH,
  SETTINGS_PATH
} from '../../../constants/routes'
import {
  HOMEWORKS_PATH,
  DASHBOARD_PATH,
  LESSONS_PATH
} from '../../../constants/rolePaths'
import {
  ADMIN_NEWS_PATH,
  ADMIN_DASHBOARD_PATH,
  ADMIN_CALENDAR_EVENTS_PATH,
  ADMIN_DOCUMENTS_PATH,
  ADMIN_CLASSROOMS_PATH,
  ADMIN_LESSON_SLOTS_PATH,
  ADMIN_LESSONS_PATH
} from '../../../constants/adminRoutes'
import DefaultAvatar from '../../../assets/default_avatar.png'
import NavbarLink from './NavbarLink'
import HSAccordion from '@preline/accordion'

const MobileMenu = (): ReactElement | null => {
  const { currentUser, logout } = useContext(CurrentUserContext)
  const { t } = useTranslation('translations')
  const { renderMobileMenu, setRenderMobileMenu } = useContext(MobileMenuContext)
  const mobileMenuRef = useRef<HTMLInputElement>(null)
  const { activeTab } = useContext(ActiveTabContext)
  const myClassPaths = [
    MY_CLASS_UNIT_PATH,
    useRolePath(LESSONS_PATH, currentUser),
    GRADES_PATH,
    ATTENDANCE_PATH,
    useRolePath(HOMEWORKS_PATH, currentUser),
    TESTS_PATH,
    MEETINGS_PATH,
    PAYMENTS_PATH
  ]
  const panelPaths = [
    ADMIN_NEWS_PATH,
    ADMIN_DASHBOARD_PATH,
    ADMIN_CALENDAR_EVENTS_PATH,
    ADMIN_DOCUMENTS_PATH,
    ADMIN_CLASSROOMS_PATH,
    ADMIN_LESSON_SLOTS_PATH,
    ADMIN_LESSONS_PATH
  ]

  useEffect(() => {
    HSAccordion.autoInit()
  })

  useEffect(() => {
    const handleClickOutside = (e: any): void => {
      if (!mobileMenuRef?.current?.contains(e.target)) {
        setRenderMobileMenu(false)
      }
      window.HSStaticMethods?.autoInit()
    }

    document.addEventListener('mousedown', handleClickOutside)

    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setRenderMobileMenu])

  const childIsActive = (paths: any[]): boolean => {
    return paths.includes(activeTab)
  }

  const activeTabClass = `
    border-r-8 border-sky-500 bg-sky-500 bg-opacity-10 text-sky-600 cursor-pointer
    py-4 px-3 ml-2 flex items-center
  `
  const navbarLinkClass = `
    h-11 w-5/6 text-base font-medium font-['Inter'] hover:border-r-2 hover:border-blue-600
    hover:bg-blue-600 hover:bg-opacity-5 flex items-center hover:text-blue-600
    py-6 px-3 ml-2 cursor-pointer
  `
  const accordionActiveTabClass = activeTabClass + 'ml-8'
  const accordionNavbarLinkClass = navbarLinkClass + 'border-l ml-8'

  // FIXME: Share this function with MainBar
  const backgroundColor = (): string => {
    if (currentUser?.role === TEACHER_ROLE) {
      return 'bg-blue-950'
    } else if (currentUser?.role === PARENT_ROLE) {
      return 'bg-[#084DC5]'
    } else {
      return 'bg-white'
    }
  }
  if (!renderMobileMenu) return null

  return (
    <div ref={mobileMenuRef} className="fixed left-0 top-0 w-5/6 sm:w-2/5 lg:hidden h-screen bg-white border-r border-zinc-200 z-[1000]">
      <div className="flex flex-col justify-between h-full overflow-y-auto">
        <div>
          <div className={`px-8 py-4 flex justify-between items-center border-b ${backgroundColor()} `}>
            <div className="flex items-center">
              <div className={`${currentUser?.role === STUDENT_ROLE ? 'text-blue-600' : 'text-white'} text-3xl font-black`}>{t('navbar.app_name')}</div>
            </div>
            <div className="cursor-pointer" onClick={() => { setRenderMobileMenu(false) }}>
              <img src={XIcon} alt="Close" className="h-6 w-auto" />
            </div>
          </div>

          <div className="py-6">
            <NavbarLink className={navbarLinkClass} activeTabClass={activeTabClass} path={currentUser ? DASHBOARD_PATH[currentUser.role] : '/'}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5 mr-3">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
              </svg>
              {t('navbar.dashboard')}
            </NavbarLink>
            <NavbarLink className={navbarLinkClass} activeTabClass={activeTabClass} path={NEWS_PATH}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5 mr-3">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z" />
              </svg>
              {t('navbar.news')}
            </NavbarLink>
          </div>

          <div className="w-full flex justify-center">
            <div className="border-b w-full" />
          </div>

          <div className="hs-accordion-group border-l">

            <div className="w-full py-2">
              <div
                id="my-class-accordion"
                className={`hs-accordion ${childIsActive(myClassPaths) ? 'active' : ''}`}
              >
                <button
                  className="hs-accordion-toggle hs-accordion-active:text-blue-600 pl-5 pr-4 py-3 inline-flex items-center gap-x-3 w-full font-semibold text-start text-gray-800 text-gray-500 focus:outline-none rounded-lg disabled:opacity-50 disabled:pointer-events-none"
                  aria-expanded="true" aria-controls="my-class-accordion-content">
                  <div className="flex justify-between w-full">
                    <div className="flex">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5 mr-3">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                      </svg>
                      Moja Klasa
                    </div>
                    {/* FIXME: Active accordion does not work */}
                    <svg className="hs-accordion-active:hidden block w-6 h-6" xmlns="http://www.w3.org/2000/svg"
                      fill="none" stroke="currentColor" strokeWidth="2"
                      strokeLinecap="round" strokeLinejoin="round">
                      <path d="m6 9 6 6 6-6"></path>
                    </svg>
                    <svg className="hs-accordion-active:block hidden" xmlns="http://www.w3.org/2000/svg"
                      width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                      strokeLinecap="round" strokeLinejoin="round">
                      <path d="m18 15-6-6-6 6"></path>
                    </svg>
                  </div>
                </button>
                <div id="my-class-accordion-content"
                  className={`hs-accordion-content ${childIsActive(myClassPaths) ? '' : 'hidden'} w-full overflow-hidden transition-[height] duration-300`}
                  role="region"
                  aria-labelledby="my-class-accordion">
                  <NavbarLink className={accordionNavbarLinkClass} activeTabClass={accordionActiveTabClass} path={MY_CLASS_UNIT_PATH}>
                    {t('navbar.my_class_unit')}
                  </NavbarLink>
                  <NavbarLink path={useRolePath(LESSONS_PATH, currentUser)} className={accordionNavbarLinkClass} activeTabClass={accordionActiveTabClass}>
                    {t('navbar.subjects')}
                  </NavbarLink>
                  <NavbarLink className={accordionNavbarLinkClass} activeTabClass={accordionActiveTabClass} path={GRADES_PATH}>
                    {t('navbar.grades')}
                  </NavbarLink>
                  <NavbarLink className={accordionNavbarLinkClass} activeTabClass={accordionActiveTabClass} path={ATTENDANCE_PATH}>
                    {t('navbar.attendance')}
                  </NavbarLink>
                  <NavbarLink className={accordionNavbarLinkClass} activeTabClass={accordionActiveTabClass} path={currentUser ? HOMEWORKS_PATH[currentUser.role] : '/'}>
                    {t('navbar.homeworks')}
                  </NavbarLink>
                  <NavbarLink className={accordionNavbarLinkClass} activeTabClass={accordionActiveTabClass} path={TESTS_PATH}>
                    {t('navbar.tests')}
                  </NavbarLink>
                  <NavbarLink className={accordionNavbarLinkClass} activeTabClass={accordionActiveTabClass} path={MEETINGS_PATH}>
                    {t('navbar.meetings')}
                  </NavbarLink>
                  <NavbarLink className={accordionNavbarLinkClass} activeTabClass={accordionActiveTabClass} path={PAYMENTS_PATH}>
                    {t('navbar.payments')}
                  </NavbarLink>
                </div>
              </div>
            </div>

            {currentUser?.admin &&
            <>
              <div className="w-full flex justify-center">
                <div className="border-b w-full" />
              </div>
              <div className="w-full py-2">
                <div
                  id="panel-accordion"
                  className={`hs-accordion ${childIsActive(panelPaths) ? 'active' : ''}`}
                >
                  <button
                    className="hs-accordion-toggle hs-accordion-active:text-blue-600 pl-5 pr-4 py-3 inline-flex items-center gap-x-3 w-full font-semibold text-start text-gray-800 text-gray-500 focus:outline-none rounded-lg disabled:opacity-50 disabled:pointer-events-none"
                    aria-expanded="true"
                    aria-controls="panel-accordion-content"
                  >
                    <div className="flex justify-between w-full">
                      <div className="flex">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5 mr-3">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
                        </svg>

                        Panel Admina
                      </div>
                      {/* FIXME: Active accordion does not work */}
                      <svg className="hs-accordion-active:hidden block w-6 h-6" xmlns="http://www.w3.org/2000/svg"
                        fill="none" stroke="currentColor" strokeWidth="2"
                        strokeLinecap="round" strokeLinejoin="round">
                        <path d="m6 9 6 6 6-6"></path>
                      </svg>
                      <svg className="hs-accordion-active:block hidden" xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                        strokeLinecap="round" strokeLinejoin="round">
                        <path d="m18 15-6-6-6 6"></path>
                      </svg>
                    </div>
                  </button>
                  <div
                    id="panel-accordion-content"
                    className={`hs-accordion-content ${childIsActive(panelPaths) ? '' : 'hidden'} w-full overflow-hidden transition-[height] duration-300`}
                    role="region"
                    aria-labelledby="panel-accordion-content"
                  >
                    <NavbarLink path={ADMIN_DASHBOARD_PATH} className={accordionNavbarLinkClass} activeTabClass={accordionActiveTabClass}>
                      {t('admin.navbar.dashboard')}
                    </NavbarLink>
                    <NavbarLink path={ADMIN_NEWS_PATH} className={accordionNavbarLinkClass} activeTabClass={accordionActiveTabClass}>
                      {t('admin.navbar.news')}
                    </NavbarLink>
                    <NavbarLink path={ADMIN_CALENDAR_EVENTS_PATH} className={accordionNavbarLinkClass} activeTabClass={accordionActiveTabClass}>
                      {t('admin.navbar.calendar_events')}
                    </NavbarLink>
                    <NavbarLink path={ADMIN_DOCUMENTS_PATH} className={accordionNavbarLinkClass} activeTabClass={accordionActiveTabClass}>
                      {t('admin.navbar.documents')}
                    </NavbarLink>
                    <NavbarLink path={ADMIN_CLASSROOMS_PATH} className={accordionNavbarLinkClass} activeTabClass={accordionActiveTabClass}>
                      {t('admin.navbar.classrooms')}
                    </NavbarLink>
                    <NavbarLink path={ADMIN_LESSON_SLOTS_PATH} className={accordionNavbarLinkClass} activeTabClass={accordionActiveTabClass}>
                      {t('admin.navbar.lesson_slots')}
                    </NavbarLink>
                    <NavbarLink path={ADMIN_LESSONS_PATH} className={accordionNavbarLinkClass} activeTabClass={accordionActiveTabClass}>
                      {t('admin.navbar.lessons')}
                    </NavbarLink>
                  </div>
                </div>
              </div>
            </>
            }
          </div>

          <div className="w-full flex justify-center">
            <div className="border-b w-full" />
          </div>

          <div className="py-6">
            <NavbarLink className={navbarLinkClass} activeTabClass={activeTabClass} path={MESSAGES_PATH}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-4 w-4 mr-2">
                <path d="M3 4a2 2 0 0 0-2 2v1.161l8.441 4.221a1.25 1.25 0 0 0 1.118 0L19 7.162V6a2 2 0 0 0-2-2H3Z" />
                <path d="m19 8.839-7.77 3.885a2.75 2.75 0 0 1-2.46 0L1 8.839V14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V8.839Z" />
              </svg>

              {t('navbar.messages')}
            </NavbarLink>
            <NavbarLink className={navbarLinkClass} activeTabClass={activeTabClass} path={SETTINGS_PATH}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-4 w-4 mr-2">
                <path fillRule="evenodd" d="M7.84 1.804A1 1 0 0 1 8.82 1h2.36a1 1 0 0 1 .98.804l.331 1.652a6.993 6.993 0 0 1 1.929 1.115l1.598-.54a1 1 0 0 1 1.186.447l1.18 2.044a1 1 0 0 1-.205 1.251l-1.267 1.113a7.047 7.047 0 0 1 0 2.228l1.267 1.113a1 1 0 0 1 .206 1.25l-1.18 2.045a1 1 0 0 1-1.187.447l-1.598-.54a6.993 6.993 0 0 1-1.929 1.115l-.33 1.652a1 1 0 0 1-.98.804H8.82a1 1 0 0 1-.98-.804l-.331-1.652a6.993 6.993 0 0 1-1.929-1.115l-1.598.54a1 1 0 0 1-1.186-.447l-1.18-2.044a1 1 0 0 1 .205-1.251l1.267-1.114a7.05 7.05 0 0 1 0-2.227L1.821 7.773a1 1 0 0 1-.206-1.25l1.18-2.045a1 1 0 0 1 1.187-.447l1.598.54A6.992 6.992 0 0 1 7.51 3.456l.33-1.652ZM10 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clipRule="evenodd" />
              </svg>
              {t('navbar.settings')}
            </NavbarLink>

            <div className={navbarLinkClass} onClick={logout}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-4 w-4 mr-2">
                <path fillRule="evenodd" d="M17 4.25A2.25 2.25 0 0 0 14.75 2h-5.5A2.25 2.25 0 0 0 7 4.25v2a.75.75 0 0 0 1.5 0v-2a.75.75 0 0 1 .75-.75h5.5a.75.75 0 0 1 .75.75v11.5a.75.75 0 0 1-.75.75h-5.5a.75.75 0 0 1-.75-.75v-2a.75.75 0 0 0-1.5 0v2A2.25 2.25 0 0 0 9.25 18h5.5A2.25 2.25 0 0 0 17 15.75V4.25Z" clipRule="evenodd" />
                <path fillRule="evenodd" d="M14 10a.75.75 0 0 0-.75-.75H3.704l1.048-.943a.75.75 0 1 0-1.004-1.114l-2.5 2.25a.75.75 0 0 0 0 1.114l2.5 2.25a.75.75 0 1 0 1.004-1.114l-1.048-.943h9.546A.75.75 0 0 0 14 10Z" clipRule="evenodd" />
              </svg>
              {t('navbar.logout')}
            </div>
          </div>
        </div>

        <div className="px-6 py-4">
          <div className="flex items-center gap-3">
            <img
              src={currentUser?.avatar_url ?? DefaultAvatar}
              className="object-cover h-10 w-10 rounded-full"
              alt="Avatar"
            />
            <div>
              <div className="text-md">{currentUser?.name ?? 'Username'}</div>
              <div className="pt-1 text-xs opacity-50">{t(`navbar.roles.${currentUser?.role}`)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileMenu
