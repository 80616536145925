import React, { type ReactElement, type FormEvent, useState, useEffect } from 'react'
import { renderToString } from 'react-dom/server'
import { Link } from 'react-router-dom'
import { ADVANCED_SEARCHING_PATH } from '../../constants/routes'
import HSComboBox from '@preline/combobox'
// import { PARENT_ROLE, TEACHER_ROLE } from '../../constants/roles'

export const SearchBar = ({ apiFetchResultsUrl }: { apiFetchResultsUrl: string }): ReactElement => {
  const [query, setQuery] = useState('')
  const comboBoxConfig = {
    groupingType: 'default',
    isOpenOnFocus: true,
    preventAutoPosition: true,
    gap: 10,
    apiGroupField: 'category',
    apiDataPart: 'data',
    apiUrl: process.env.REACT_APP_API_URL + apiFetchResultsUrl,
    apiHeaders: { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } },
    apiSearchQuery: 'query',
    // FIXME: Move this to props
    apiQuery: 'status=active',
    outputItemTemplate: renderToString(<OutputItemTemplate />),
    groupingTitleTemplate: renderToString(<GroupingTitleTemplate />),
    outputEmptyTemplate: renderToString(<OutputEmptyTemplate />),
    outputLoaderTemplate: renderToString(<OutputLoaderTemplate />)
  }

  const redirectToAdvancedSearch = (e: FormEvent): void => {
    e.preventDefault()
    window.location.href = `${ADVANCED_SEARCHING_PATH}?query=${query}`
  }

  useEffect(() => {
    HSComboBox.autoInit()
  }, [])

  // const searchBorder = currentUser?.role === PARENT_ROLE ? 'border-white' : 'border-zinc-200'
  // const searchBackground = currentUser?.role === PARENT_ROLE ? 'bg-[#084DC5]' : 'bg-blue-600'
  // const borderClass = currentUser?.role === TEACHER_ROLE ? '' : 'border-y border-l'

  return (
    <div className="lg:flex h-10 rounded-lg border border-zinc-200 items-center w-full max-w-2xl mx-auto">
      <div
        className="relative w-full"
        id="search-bar"
        data-hs-combo-box={JSON.stringify(comboBoxConfig)}
      >
        <form
          className="relative flex items-center w-full"
          onSubmit={redirectToAdvancedSearch}
        >
          <input
            className="block py-2 px-4 w-full rounded-l-lg text-sm focus:outline-none disabled:opacity-50 disabled:pointer-events-none"
            type="text"
            role="combobox"
            aria-expanded="false"
            placeholder="Type a name"
            data-hs-combo-box-input=""
            onChange={(e) => { setQuery(e.target.value) }}
          />
          <Link
            className="bg-[#0779ff] rounded-r-lg h-full p-2 flex items-center"
            onClick={() => { window.location.href = `${ADVANCED_SEARCHING_PATH}?query=${query}` }}
            to="#"
          >
            <svg
              className="shrink-0 h-6 w-6 text-white"
              data-hs-combo-box-toggle
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="11" cy="11" r="8"></circle>
              <path d="m21 21-4.3-4.3"></path>
            </svg>
          </Link>
        </form>

        <div
          className="absolute z-[100] w-full bg-white border border-gray-200 rounded-lgm hidden hs-combo-box-selected:block"
          style={{ left: 0, right: 0 }}
          data-hs-combo-box-output=""
        >
          <div
            className="max-h-[400px] rounded-b-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300"
            data-hs-combo-box-output-items-wrapper=""
          />
          <div className="w-full flex justify-center">
            <div className="border-b w-full" />
          </div>
          <SearchBarFooter query={query} />
        </div>
      </div>
    </div>
  )
}

const SearchBarFooter = ({ query }: { query: string }): ReactElement => {
  return (
    <Link
      onClick={() => { window.location.href = `${ADVANCED_SEARCHING_PATH}?query=${query}` }}
      to="#"
      className="h-11 rounded-lg justify-end items-center gap-3 inline-flex w-full"
      data-hs-combo-box-close
    >
      <div className="grow shrink basis-0 h-5 justify-end items-center gap-2.5 flex">
        <div className="flex justify-end items-center gap-2">
          <div className="text-[#0e459a] text-sm font-medium font-['DM Sans'] leading-tight">Zobacz wszystkie wyniki</div>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="blue" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
          </svg>
        </div>
      </div>
    </Link>
  )
}

const GroupingTitleTemplate = (): ReactElement => {
  return <div className="text-xs uppercase text-gray-500 m-3 mb-1" data-hs-combo-box-group-title></div>
}

const OutputItemTemplate = (): ReactElement => {
  return (
    <div data-hs-combo-box-output-item>
      <div className="flex justify-between hover:bg-gray-100">
        <span className="flex items-center cursor-pointer py-2 px-4 w-full text-sm text-gray-800">
          <div className="flex items-center w-full">
            <div className="flex items-center justify-center rounded-full size-6 overflow-hidden me-2.5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                />
              </svg>
            </div>
            <div className="flex-grow" data-hs-combo-box-output-item-field="name" data-hs-combo-box-search-text />
          </div>
        </span>
        <div className="text-xs uppercase text-gray-500 m-3 mb-1">
          <div data-hs-combo-box-output-item-field="type" />
        </div>
      </div>
    </div>
  )
}

const OutputEmptyTemplate = (): ReactElement => {
  return (
    <div className="py-2 px-4">
      Nie znaleziono wyników
    </div>
  )
}

const OutputLoaderTemplate = (): ReactElement => {
  return (
    <div className="flex justify-center items-center py-2 px-4 text-sm text-gray-800 rounded-lg bg-white">
      <div
        className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
        role="status" aria-label="loading">
        <span className="sr-only">Ładowanie...</span>
      </div>
    </div>
  )
}

export default SearchBar
